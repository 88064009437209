<template>
<div class="row row-custom" v-if="Product">
    <div class="col-md-8">
        <div class="card text-right">
        <div class="card-header">
            تفاصيل صانع المحتوى
        </div>
        <div class="card-body">
            <div class="row">   
             <table class="table">
                 <tr><td>رقم المنتج </td><td>:</td><td>{{Product.offline_id}}</td></tr>
                 <tr><td>الاسم بالعربي </td><td>:</td><td>{{Product.name.ar}}</td></tr>
                 <tr><td>الاسم بالانجليزي</td><td>:</td><td>{{Product.name.en}}</td></tr>
                 <tr><td>الماركة</td><td>:</td><td>{{Product.brand}}</td></tr>
                 <tr><td>الوصف بالعربي</td><td>:</td><td>{{Product.description.ar}}</td></tr>
                 <tr><td>الوصف بالانجليزي</td><td>:</td><td>{{Product.description.en}}</td></tr>
                 <tr><td>متوفر</td><td>:</td><td>{{Product.is_available?'نعم':'لا'}}</td></tr>
                 <tr><td>صنف مميز</td><td>:</td><td>{{Product.is_special?'نعم':'لا'}}</td></tr>
                 <tr><td>يوجد تخفيض</td><td>:</td><td>{{Product.is_there_discount?'نعم':'لا'}}</td></tr>
                 <tr><td>موديل</td><td>:</td><td>{{Product.modal}}</td></tr>
                 <tr><td>الكمية</td><td>:</td><td>{{Product.quantity}}</td></tr>
                 <tr><td>السعر</td><td>:</td><td>{{Product.selling_price}}</td></tr>
                 <tr><td>السعر بعد التخفيض</td><td>:</td><td>{{Product.selling_price_after_discount}}</td></tr>
                 <tr><td>السعر بعد الضريبة</td><td>:</td><td>{{Product.selling_price_after_tax}}</td></tr>
                 <tr><td>الوصف المختصر بالعربي</td><td>:</td><td>{{Product.short_description.ar}}</td></tr>
                 <tr><td>الوصف المختصر بالانجليزي</td><td>:</td><td>{{Product.short_description.en}}</td></tr>
                 <tr><td>هاشتاق</td><td>:</td><td>{{Product.tags}}</td></tr>
                 <tr>
                     <td>المرفقات</td><td>:</td><td>
                      <div v-for="(attachment,index) in Product.attachments" :key="index">   
                         <img width="100" height="100" :src="'https://pode.ly'+attachment.img_path"/>
                      </div>
                     </td>
                </tr>
                <tr>
                     <td>الالوان</td><td>:</td><td>
                      <div v-for="(color,index) in Product.colors" :key="index">   
                         <span>{{color.color.name.ar}}</span>
                      </div>
                     </td>
                </tr>


             </table>
            </div>                      
        </div>

    </div>

    </div>
</div>

</template>

<script>
import {mapState,mapMutations} from 'vuex';
import store from '../../../store/index'
export default {
      computed:{
    ...mapState({
        Product:state=>state.admin.products.Product.data,
        }),

      },
      methods:{
  
      },
     beforeRouteEnter(to,from,next){    
        const uuid = to.params.uuid;
        store.dispatch('admin/fetchProduct', uuid).then((response) => {
            next(true);
        }).catch(error=>{
            next(false);
        })
    },
}
</script>

<style scoped>
.row-custom{
    justify-content: center;
}

.card .card-header {
    background: #16918b;
    color: white;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Tajawal';
}
.card .card-body{
    font-size: 14px;
    font-family: 'Tajawal';
    font-weight: 600;
}
</style>